<template>
  <div class="SuperGroupHeader">
    <svgicon
      class="categoryIcon"
      :icon="`categories/${superGroup.categoryId}`" />
    <span class="categoryLabel">{{ $t(`dashboard.general.categoryLabels.${superGroup.categoryId}`) }}</span>
  </div>
</template>

<script>
export default {
    inject: ['config'],
    props: {
        superGroupIndex: {
            type: Number,
            required: true
        }
    },
    computed: {
        superGroup() {
            return this.config.superGroups[this.superGroupIndex];
        }
    }
};
</script>

<style scoped lang="scss">
.SuperGroupHeader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.categoryIcon {
    $size: 1.5em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.9);
    margin-right: 1em;
}
.categoryLabel {
    color: rgba(white, 0.8);
    font-weight: 600;
    font-size: 140%;
}
</style>
