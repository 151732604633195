<template>
  <div
    class="MnemonicImage"
    :class="{ 'is-numbershape': !!numbershape }"
    :style="style">
    <AnimatedNumbershape
      v-if="!!numbershape"
      class="numbershape"
      :number="numbershape.number"
      :modifier="numbershape.modifier"
      :reversed="reversed"
      :play="focus" />
  </div>
</template>

<script>
import AnimatedNumbershape from '@/components/AnimatedNumbershape';
const modifierBackgrounds = require.context('./modifier-backgrounds/', true, /\.jpg$/);

export default {
    components: { AnimatedNumbershape },
    props: {
        image: {
            type: String
        },
        focus: {
            type: Boolean,
            default: false
        },
        reversed: {
          type: Boolean,
          default: false,
        }
    },
    data() {
        return {};
    },
    computed: {
        numbershape() {
            if (this.image && /\d/.test(this.image[0])) {
                const m = this.image.match(/^(\d{1,3})(?:-(\w+))?$/);
                if (m) {
                    const origNumber = m[1];

                    if (origNumber.length === 3) {
                        return {
                            number: this.image.slice(1),
                            modifier: this.image[0]
                        };
                    } else {
                        return {
                            number: this.image
                        };
                    }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        style() {
            if (this.numbershape && this.numbershape.modifier) {
                return {
                    backgroundColor: 'transparent',
                    backgroundImage: `url(${modifierBackgrounds('./modifier-' + this.numbershape.modifier + '.jpg')})`
                };
            } else if (!this.numbershape && this.image) {
                return {
                    backgroundImage: `url(${this.image}`
                };
            } else {
                return null;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$size: 4em;

.MnemonicImage {
    width: $size;
    height: $size;
    background-color: white;
    color: black;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.numbershape {
    width: 100%;
    height: 100%;
}
</style>
