<template>
  <div
    class="MultipleChoiceInputStandard"
    :class="{ done }">
    <div class="items">
      <div
        v-for="(item, i) of items"
        :key="i"
        class="Item --global-clickable"
        :class="{ selected: selections.indexOf(i) !== -1 }"
        :style="style"
        @click="!done && $emit('item-click', i)">
        <span class="Item__content">{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { TimelineMax, Power2 } from 'gsap';
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';

export default {
    props: ['items', 'selections'],
    inject: ['theme'],
    data() {
        return {
            done: false
        };
    },
    computed: {
        style() {
            return {
                backgroundColor: this.theme.primaryActiveColor
                // borderColor: this.theme.primaryActiveColor
                // primaryActiveColor
            };
        }
    },
    beforeDestroy() {
        if (this.tl) {
            this.tl.kill();
        }
    },
    methods: {
        playFeedbackWrong() {
            this.done = true;
            return new Promise(resolve => {
                const shakeSpeed = 0.1;
                const elementsToShake = this.$el.querySelectorAll('.Item.selected');

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementsToShake, shakeSpeed, {
                    x: -7,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToShake, shakeSpeed, {
                    repeat: 4,
                    x: 5,
                    yoyo: true,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToShake, shakeSpeed, {
                    x: 0
                });
            });
        },
        playFeedbackCorrect() {
            this.done = true;
            soundService.play(correctSound);
            return new Promise(resolve => {
                const elementsToAnimate = this.$el.querySelectorAll('.Item.selected');
                const tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementsToAnimate, 0.3, {
                    scale: 1.1,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToAnimate, 0.3, {
                    scale: 1,
                    ease: Power2.easeInOut
                });
            });
        }
    }
};
</script>

<style scoped lang="scss">
.MultipleChoiceInputStandard {
    // padding: 1em;
    &.done {
        .Item:not(.selected) {
            transition: opacity 0.2s;
            opacity: 0;
        }
    }
}

.items {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
}

.Item {
    padding: 1em 1.5em;
    margin: 0 2em;
    background-color: white; //#301b82;
    border-radius: 0.3em;
    color: mix(black, white, 80%);
    text-align: center;
    text-transform: lowercase;

    &:not(:first-child) {
        margin-top: 0.5em;
    }

    &.selected {
        // background-color: white;
        // color: black;
        // border-color: rgb(213, 120, 222);
        background-color: rgb(213, 120, 222);
        color: white;
    }
    &:not(.selected) {
        // background-color: transparent !important;
    }
}
.Item__content {
    font-size: 120%;
}
</style>
