<template>
  <ConfigurationSection
    class="LifeskillRangeSelectionSection"
    :title="$t('title')">
    <div
      v-for="category of categories"
      :key="category.id"
      class="Category --global-clickable"
      :class="{ selected: selectedCategories[category.id], disabled: category.learnProgress < 1 }"
      @click="toggleCategory(category.id)">
      <svgicon
        class="Category__icon"
        :icon="`categories/${category.id}`" />
      <span class="Category__label">{{ $t(`dashboard.general.categoryLabels.${category.id}`) }}</span>
      <span class="Category__range">{{ category.lifeskillRange }}</span>
      <svgicon
        class="Category__arrowIcon"
        icon="checkmark" />
    </div>
  </ConfigurationSection>
</template>

<translations>
  title: 'Select Categories'
  title_no: 'Velg øvingskodene dine'
</translations>

<script>
import flatten from 'lodash-es/flatten';
import ConfigurationSection from '@/modules/systems-shared/components/ConfigurationSection';

export default {
    components: { ConfigurationSection },
    inject: ['config', 'module'],
    data() {
        return {
            selectedCategories: {}
        };
    },
    computed: {
        categories() {
            return this.config.superGroups.map(superGroup => {
                return {
                    id: superGroup.categoryId,
                    lifeskillRange: superGroup.lifeskillRange,
                    learnProgress:
                        superGroup.groupIndices
                            .map(this.module.getters('getGroupProgress'))
                            .reduce((a, b) => a + b, 0) / superGroup.groupIndices.length
                };
            });
        },
        selectedNumbers() {
            const groupIndices = flatten(
                this.config.superGroups
                    .filter(superGroup => this.selectedCategories[superGroup.categoryId])
                    .map(superGroup => superGroup.groupIndices)
            );
            const itemIds = flatten(groupIndices.map(groupIndex => this.config.itemGroups[groupIndex].itemIds));
            return itemIds.map(itemId => itemId.substr(0, 2));
        }
    },
    watch: {
        selectedNumbers(numbers) {
            this.$emit('selected-numbers-changed', numbers);
        }
    },
    methods: {
        toggleCategory(categoryId) {
            this.$set(this.selectedCategories, categoryId, !this.selectedCategories[categoryId]);
        }
    }
};
</script>

<style scoped lang="scss">
.LifeskillRangeSelectionSection {
}

.Category {
    padding: 0.5em 2em;
    display: flex;
    align-items: center;
    color: rgba(white, 0.8);

    &.selected {
    }
    &:not(.selected) {
        .Category__arrowIcon {
            opacity: 0;
        }
    }
    &.disabled {
        opacity: 0.5;
        // pointer-events: none;
    }
}
.Category__icon {
    $size: 1.2em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.9);
    margin-right: 1em;
}
.Category__label {
    // font-size: 120%;
}
.Category__range {
    flex: 1;
    margin-left: 0.5em;
    // font-size: 120%;
}
.Category__arrowIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.8);
}
</style>
