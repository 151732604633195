import norwegianLifeAreas from '@/assets/lifeareas_no.json';
import englishLifeAreas from '@/assets/lifeareas_en.json';

const images = require.context('./images', true, /\.jpg$/);

function getImageUrl(kind, number) {
    try {
        return images(`./${kind}/${number}.jpg`);
    } catch (err) {
        return null;
    }
}

let data = [];
norwegianLifeAreas.forEach((item, index) => {
    data.push({
        number: item.number,
        no: item.data,
        en: englishLifeAreas[index].data
    });
});

data.forEach(itemRaw => {
    itemRaw.objectImage = getImageUrl('object', itemRaw.number);
    itemRaw.personImage = getImageUrl('person', itemRaw.number);
    itemRaw.actionImage = getImageUrl('action', itemRaw.number);
});

export default data;
