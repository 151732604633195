<template>
  <div class="Item">
    <div class="code">
      {{ item['object' + '_code'] }}
    </div>

    <div class="animationDescription">
      {{ item.animation_description }}
    </div>
    <div class="divider" />

    <AnimatedNumbershape
      v-observe-visibility="visibilityChanged"
      :number="item.id"
      color="white"
      play
      :paused="!animationIsVisible" />

    <div class="textContainer">
      <div
        class="explanation"
        v-html="item['object' + '_explanation']" />
    </div>
  </div>
</template>

<script>
import AnimatedNumbershape from '@/components/AnimatedNumbershape';

export default {
    components: { AnimatedNumbershape },
    inject: ['config'],
    props: {
        itemId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            animationIsVisible: true
        };
    },
    computed: {
        item() {
            return this.config.items[this.itemId];
        }
    },
    methods: {
        visibilityChanged(isVisible, entry) {
            this.animationIsVisible = isVisible;
        }
    }
};
</script>

<style scoped lang="scss">
.Item {
    background-color: rgba(black, 0.05);
    border-radius: 0.5em;
    padding: 1em;
    color: white;
}

.animationDescription {
    color: rgba(white, 0.6);
    margin-top: 1em;
    line-height: 1.3em;
    text-align: center;
}
.divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 1em 0;
}

.code {
    color: rgba(white, 0.9);
    font-size: 120%;
    text-align: center;
}
.explanation {
    margin-top: 1em;
    color: rgba(white, 0.6);
    line-height: 1.3em;

    /deep/ u {
        color: rgba(white, 0.9);
        font-style: italic;
        text-decoration: none;
    }
}

.AnimatedNumbershape {
    $size: 10em;
    width: $size;
    height: $size;
    margin: auto;
}
</style>
