import categories from '@/categories';

function calcGroupSizes(numberOfItems, numberOfGroups) {
    const smallestGroupSize = Math.floor(numberOfItems / numberOfGroups);
    const remainder = numberOfItems % numberOfGroups;
    return Array.from(new Array(numberOfGroups), (val, index) => smallestGroupSize + (remainder - index > 0 ? 1 : 0));
}

export default categories.map(({ id, range }) => {
    const [start, end] = range.map(r => parseInt(r, 10));
    const rangeSize = end - start + 1;
    const numberOfGroups = Math.min(Math.floor(rangeSize / 5), 3);

    return {
        id,
        range: range.join('-'),
        groupSizes: calcGroupSizes(rangeSize, numberOfGroups)
    };
});
