import Hammer from 'hammerjs';

export default function setupDragAndDrop({
    source,
    sourceSelector,
    targetSelector,
    dragStart,
    dragMove,
    dragTargetChange,
    dragEnd,
    click
}) {
    let isDragging = false;
    let activeTarget = null;
    let sourceId = null;

    const hammer = new Hammer.Manager(source, {
        touchAction: 'auto',
        recognizers: [
            [Hammer.Press, { time: 0 }],
            [Hammer.Pan, { threshold: 16 }], // NOTE: threshold is slighly less then the threshold for Carousel swipe
            [Hammer.Tap, {}, ['press']]
        ]
    });

    function handlePanStart(event) {
        const extraCondition = event.angle < -10 && event.angle > -(180 - 10); // NOTE: draging upwards + sideways

        if (extraCondition && sourceId) {
            Hammer.disableOthersHack = true;
            isDragging = true;
            dragStart(sourceId);
            dragMove(sourceId, event.center.x, event.center.y);
        }
    }
    function handlePanMove(event) {
        if (!isDragging) {
            return;
        }
        dragMove(sourceId, event.center.x, event.center.y);
        const oldValue = activeTarget;
        let newValue = null;
        const element = document.elementFromPoint(event.center.x, event.center.y);
        if (element && element.matches(targetSelector)) {
            newValue = element.dataset.id;
        }
        if (newValue !== oldValue) {
            activeTarget = newValue;
            dragTargetChange(newValue, oldValue);
        }
    }
    function handlePanEnd(event) {
        if (!isDragging) {
            return;
        }
        Hammer.disableOthersHack = false;
        dragEnd(sourceId, activeTarget);
        sourceId = null;
        isDragging = false;
        activeTarget = null;
    }
    function handlePanCancel() {
        if (!isDragging) {
            return;
        }
        Hammer.disableOthersHack = false;
    }
    function handleTap(event) {
        if (event.target.matches(sourceSelector)) {
            if (click) {
                const sourceId = event.target.dataset.id;
                click(sourceId);
            }
        }
    }
    function handlePress(event) {
        if (event.target.matches(sourceSelector)) {
            sourceId = event.target.dataset.id;
        }
    }

    hammer.on('panstart', handlePanStart);
    hammer.on('panmove', handlePanMove);
    hammer.on('panend', handlePanEnd);
    hammer.on('pancancel', handlePanCancel);
    hammer.on('tap', handleTap);
    hammer.on('press', handlePress);

    return function () {
        hammer.destroy();
    };
}
