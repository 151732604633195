<template>
  <div
    class="MatchTargetItem"
    :class="{ hover, matched, disabled, useLabel: matched && other.label && !other.image }">
    <template v-if="matched">
      <MnemonicImage
        v-if="isNumbershape"
        :reversed="true"
        :image="other.image" />
      <div
        v-else-if="other.image"
        class="image"
        :style="{ backgroundImage: `url(${other.image})` }" />
      <div
        v-else
        class="label">
        {{ other.label }}
      </div>
    </template>
    <template v-else>
      <div class="placeholderLabel">
        {{ data.label }}
      </div>
    </template>
  </div>
</template>

<script>
import MnemonicImage from '@/components/MnemonicImage';

export default {
    components: { MnemonicImage },
    props: {
        data: {
            type: Object,
            required: true
        },
        other: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        matched: Boolean,
        hover: Boolean
    },
    computed: {
        isNumbershape() {
            return this.other && this.other.image && /\d/.test(this.other.image[0]);
        }
    }
};
</script>

<style scoped lang="scss">
$borderRadius: 0.5em;

.MatchTargetItem {
    background-color: rgba(0,0,0,0.8);
    $size: 5em;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    border: 1px dashed rgba(white, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $borderRadius;

    transition: background-color 0.5s;

    & > * {
        pointer-events: none;
    }

    &.hover {
        background-color: rgba(white, 0.5);
    }
    &.matched {
        pointer-events: none;
        border: 1px transparent;

        animation: pulse 0.5s;

        @keyframes pulse {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.1);
            }
            0% {
                transform: scale(1);
            }
        }

        &.useLabel {
            background-color: white;
        }
    }

    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }
}

.image {
    $size: 100%;
    width: $size;
    height: $size;
    border-radius: $borderRadius;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.MnemonicImage {
    $size: 100%;
    width: $size;
    height: $size;
    border-radius: $borderRadius;
}

.label {
    color: rgba(black, 0.9);
    font-weight: bold;
    font-size: 140%;
}
</style>
