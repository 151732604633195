<template>
  <ConfigurationSection
    class="GameSelectionSection"
    :title="$t('title')">
    <div
      v-for="gameId of gameIds"
      :key="gameId"
      class="Game --global-clickable"
      :class="{ selected: selectedGameId === gameId }"
      @click="$emit('game-selected', gameId)">
      <PracticeGameItem
        class="Game__content"
        :game-id="gameId" />
      <svgicon
        class="Game__arrowIcon"
        icon="checkmark" />
    </div>
  </ConfigurationSection>
</template>

<translations>
  title: 'Select game'
  title_no: 'Velg spill'
</translations>

<script>
import ConfigurationSection from './ConfigurationSection';
import PracticeGameItem from './PracticeGameItem';

export default {
    components: { ConfigurationSection, PracticeGameItem },
    props: {
        gameIds: {
            type: Array,
            required: true
        },
        selectedGameId: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.GameSelectionSection {
}
.Game {
    padding: 0.5em 2em;
    display: flex;
    align-items: center;
    color: rgba(white, 0.8);
    &.selected {
    }
    &:not(.selected) {
        .Game__arrowIcon {
            opacity: 0;
        }
    }
}
.Game__content {
    flex: 1;
}
.Game__label {
    flex: 1;
}
.Game__arrowIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.8);
}
</style>
