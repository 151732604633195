import keyBy from 'lodash-es/keyBy';
import last from 'lodash-es/last';
import Item from './components/Item';
import MemorizeItem from './components/MemorizeItem';
import PracticeConfiguration from './components/PracticeConfiguration';
import SuperGroupHeader from '@/modules/system-olpa/components/SuperGroupHeader';
import backgroundImage from './images/background.png';

import itemsRaw from '@/modules/system-olpa/data';
import { chunkItems, superGroups } from '@/modules/system-olpa/config';

export default function generateConfig({ language }) {
    const items = itemsRaw.map(rawItem => {
        const item = Object.assign({ id: `${rawItem.number}`, label: `${rawItem.number}` }, rawItem[language]);
        // item.objectImage = rawItem.objectImage
        item.objectImage = `${rawItem.number}`;
        return item;
    });
    const itemsMap = keyBy(items, item => item.id);
    return {
        items: itemsMap,
        itemIds: items.map(item => item.id),
        superGroups,
        itemGroups: chunkItems(items).map((sublist, index) => {
            return {
                index,
                label: `${sublist[0].id}-${last(sublist).id}`,
                itemIds: sublist.map(x => x.id)
            };
        }),
        BrowseItem: Item,
        MemorizeItem,
        SuperGroupHeader,
        PracticeConfiguration,
        backgroundImage,
        systemId: 'double',

        getImagesInGroup(group) {
            return group.itemIds.map(itemId => itemsMap[itemId].objectImage);
        }
    };
}
