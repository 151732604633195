import shuffle from 'lodash-es/shuffle';
import { pickUniqueRandomItems } from '@/modules/memogenius-game/utils';

const module = {
    namespaced: true,
    state() {
        return {
            pageIndex: 0,
            itemIndex: 0,
            pages: [],
            items: {}
        };
    },
    actions: {
        load({ state, commit }, { config, groupIndex }) {
            const group = config.itemGroups[groupIndex];
            let pages;
            let items;
            function formatMCOption(itemId) {
                const item = config.items[itemId];
                return `${item.life_area}`;
            }
            if (config.systemId === 'olpa') {
                pages = group.itemIds.map(itemId => {
                    return {
                        itemIds: [itemId] // single item needs no wrapper
                    };
                });
                items = {};
                group.itemIds.forEach(itemId => {
                    let mcOptions = pickUniqueRandomItems(group.itemIds, 4);
                    if (mcOptions.indexOf(itemId) === -1) {
                        mcOptions.push(itemId);
                        mcOptions.shift();
                        mcOptions = shuffle(mcOptions);
                    }

                    let opaTestOptions = [];
                    mcOptions.forEach(itemId => {
                        opaTestOptions.push(`${itemId}:o`);
                        opaTestOptions.push(`${itemId}:p`);
                        opaTestOptions.push(`${itemId}:a`);
                    });
                    opaTestOptions = shuffle(opaTestOptions);

                    items[itemId] = {
                        tests: [
                            {
                                name: 'MultipleChoiceTest',
                                itemId,
                                options: mcOptions.map(itemId => formatMCOption(itemId)), // ['ole', 'dole', 'per', '01 - focus and concentration'],
                                fasit: formatMCOption(itemId), // 'focus and concentration',
                                done: false
                            },
                            {
                                name: 'OpaTest',
                                itemId,
                                fasit: { o: `${itemId}:o`, p: `${itemId}:p`, a: `${itemId}:a` },
                                options: opaTestOptions, // [ '00-opa:o', '01-opa:o', '02-opa:a' ], // shuffled
                                done: false
                            }
                        ]
                    };
                });
            } else {
                // Just now, for alphabet
                pages = [
                    {
                        itemIds: group.itemIds,
                        wrapper: 'PageMatchTest'
                    }
                ];
                items = {};
                group.itemIds.forEach(itemId => {
                    // const origItem = config.items[itemId]
                    items[itemId] = {
                        id: itemId,
                        tests: [
                            {
                                name: 'MatchTest',
                                done: false
                            }
                        ]
                    };
                });
            }

            commit('update', {
                pages,
                items
            });
        },
        unload() {
            //
        },

        updateTest({ commit }, payload) {
            commit('updateTest', payload);
        }
    },
    mutations: {
        update(state, data) {
            Object.assign(state, data);
        },
        updateTest(state, { itemId, testIndex, data }) {
            Object.assign(state.items[itemId].tests[testIndex], data);
        }
    },

    getters: {
        progress(state) {
            let numberOfTest = 0;
            let numberOfDoneTests = 0;
            Object.values(state.items).forEach(item => {
                item.tests.forEach(test => {
                    numberOfTest++;
                    if (test.done) {
                        numberOfDoneTests++;
                    }
                });
            });
            return numberOfDoneTests / numberOfTest;
        }
    }
};

export default module;
