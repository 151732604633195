<template>
  <div class="SourceTargetInteraction">
    <slot
      name="default"
      :source-items="sourceItems"
      :target-items="targetItems" />

    <div
      v-if="currentSourceId"
      class="dragItemContainer"
      :style="{ top: `${dragInfo.y}px`, left: `${dragInfo.x}px` }">
      <slot
        :id="currentSourceId"
        name="dragItem" />
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs';
import setupDragAndDrop from '@/modules/games-shared/setupDragAndDrop';

export default {
    props: {
        targetIds: {
            type: Array,
            required: true
        },
        sourceIds: {
            type: Array,
            required: true
        },
        sourceSelector: {
            type: String,
            required: true
        },
        targetSelector: {
            type: String,
            required: true
        },
        checkMatch: {
            type: Function,
            default() {
                return function (sourceId, targetId) {
                    return sourceId === targetId;
                };
            }
        },
        initialState: {
            type: Object,
            default: null
        },
        useInternalMatchedState: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dragInfo: { x: 0, y: 0, image: null },
            currentSourceId: null,
            currentTargetId: null,
            selectedSourceId: null,

            sourceItems: this.sourceIds.map(sourceId => {
                return {
                    id: sourceId,
                    dragging: false,
                    selected: false,
                    used: false
                };
            }),
            targetItems: this.targetIds.map(targetId => {
                return {
                    id: targetId,
                    hover: false,
                    matched: false
                };
            })
        };
    },
    computed: {},
    watch: {
        currentTargetId(newTargetId, oldTargetId) {
            if (oldTargetId) {
                const targetItem = this.targetItems.find(item => item.id === oldTargetId);
                targetItem.hover = false;
            }
            if (newTargetId) {
                const targetItem = this.targetItems.find(item => item.id === newTargetId);
                targetItem.hover = true;
            }
        },
        selectedSourceId(newSourceId, oldSourceId) {
            if (newSourceId) {
                const sourceItem = this.sourceItems.find(item => item.id === newSourceId);
                sourceItem.selected = true;
            }
            if (oldSourceId) {
                const oldSourceItem = this.sourceItems.find(item => item.id === oldSourceId);
                oldSourceItem.selected = false;
            }

            if (newSourceId && !oldSourceId) {
                this.clickSetup();
            } else if (oldSourceId && !newSourceId) {
                this.clickTeardown();
            }
        }
    },
    mounted() {
        if (this.initialState) {
            Object.entries(this.initialState).forEach(([sourceId, targetId]) => {
                const targetItem = this.targetItems.find(item => item.id === targetId);
                const sourceItem = this.sourceItems.find(item => item.id === sourceId);
                targetItem.matched = true;
                sourceItem.used = true;
            });
        }

        let offsetX = 0;
        let offsetY = 0;
        this.dragAndDropTeardown = setupDragAndDrop({
            source: this.$el,
            sourceSelector: this.sourceSelector,
            targetSelector: this.targetSelector,
            dragStart: sourceId => {
                this.currentSourceId = sourceId;
                const { x, y } = this.$el.getBoundingClientRect();
                offsetX = x;
                offsetY = y;

                const sourceItem = this.sourceItems.find(item => item.id === sourceId);
                sourceItem.dragging = true;
            },
            dragMove: (sourceId, x, y) => {
                this.dragInfo.x = x - offsetX;
                this.dragInfo.y = y - offsetY;
            },
            dragTargetChange: (newValue, oldValue) => {
                this.currentTargetId = newValue || null;
            },
            dragEnd: (sourceId, targetId) => {
                this.currentTargetId = null;
                this.currentSourceId = null;

                const sourceItem = this.sourceItems.find(item => item.id === sourceId);
                sourceItem.dragging = false;

                if (targetId) {
                    const targetItem = this.targetItems.find(item => item.id === targetId);

                    if (this.checkMatch(sourceId, targetId)) {
                        if (this.useInternalMatchedState) {
                            targetItem.matched = true;
                            sourceItem.used = true;
                        }
                    }
                }
            },
            click: sourceId => {
                this.selectedSourceId = this.selectedSourceId === sourceId ? null : sourceId;
            }
        });
    },
    beforeDestroy() {
        this.dragAndDropTeardown();
        this.clickTeardown();
    },
    methods: {
        clickSetup() {
            this.hammer = new Hammer.Manager(this.$el, {
                recognizers: [[Hammer.Tap, {}]]
            });
            this.$el.onmousemove = event => {
                const element = document.elementFromPoint(event.pageX, event.pageY);
                if (element && element.matches(this.targetSelector)) {
                    this.currentTargetId = element.dataset.id;
                } else {
                    this.currentTargetId = null;
                }
            };
            this.hammer.on('tap', event => {
                if (event.target.matches(this.targetSelector)) {
                    const targetId = event.target.dataset.id;
                    if (this.checkMatch(this.selectedSourceId, targetId)) {
                        if (this.useInternalMatchedState) {
                            const targetItem = this.targetItems.find(item => item.id === targetId);
                            const sourceItem = this.sourceItems.find(item => item.id === this.selectedSourceId);
                            targetItem.matched = true;
                            sourceItem.used = true;
                        }
                    }
                    this.selectedSourceId = null;
                    this.currentTargetId = null;
                }
            });
        },
        clickTeardown() {
            this.$el.onmousemove = null;
            if (this.hammer) {
                this.hammer.destroy();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.SourceTargetInteraction {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.dragItemContainer {
    position: absolute;
    pointer-events: none;
    transform: translate(-50%, -50%);
}
</style>
