<template>
  <div
    class="ShelfItemWithImage"
    :class="{ dragging: state.dragging, selected: state.selected, useLabel: label && !image }">
    <MnemonicImage
      v-if="isNumbershape"
      :reversed="true"
      :image="image" />
    <div
      v-else-if="image"
      class="image"
      :style="{ backgroundImage: `url(${image})` }" />
    <div
      v-else-if="label"
      class="label">
      {{ label }}
    </div>
    <div class="selectionIndicator" />
  </div>
</template>

<script>
import MnemonicImage from '@/components/MnemonicImage';

export default {
    components: { MnemonicImage },
    props: {
        state: {
            type: Object,
            required: true
        },
        // id: {},
        image: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        }
        // used: {
        //   type: Boolean,
        //   default: false
        // },
        // dragging: {
        //   type: Boolean,
        //   default: false
        // },
        // selected: {
        //   type: Boolean,
        //   default: false
        // }
    },
    computed: {
        isNumbershape() {
            return this.image && /\d/.test(this.image[0]);
        }
    }
};
</script>

<style scoped lang="scss">
$size: 4em;
.ShelfItemWithImage {
    position: relative;
    width: $size;
    height: $size;
    width: var(--local-size, $size);
    height: var(--local-size, $size);

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & > * {
        pointer-events: none;
    }

    &.dragging {
        opacity: 0;
        pointer-events: none;
    }
    &.selected {
        animation: pulse 0.3s;

        @keyframes pulse {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.05);
            }
            0% {
                transform: scale(1);
            }
        }

        .selectionIndicator {
            opacity: 1;
        }
    }

    &.useLabel {
        background-color: white;
        border-radius: 0.5em;
    }
}
.image {
    width: $size;
    height: $size;
    width: var(--local-size, $size);
    height: var(--local-size, $size);

    background-size: cover; //contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0.5em;
}

.MnemonicImage {
    width: $size;
    height: $size;
    width: var(--local-size, $size);
    height: var(--local-size, $size);
    border-radius: 0.5em;
}

.label {
    color: rgba(black, 0.9);
    font-weight: bold;
    font-size: 140%;
}

.selectionIndicator {
    $offset: -2px;
    position: absolute;
    top: $offset;
    right: $offset;
    bottom: $offset;
    left: $offset;
    border: 2px solid black;
    border-radius: 0.5em * 1.3;
    opacity: 0;

    box-shadow: inset 0 0 0 1px rgba(white, 0.5);
}
</style>
