<template>
  <div class="Item">
    <div class="name">
      {{ item.number }}
    </div>
    <div class="divider" />

    <div
      v-for="kind of ['object', 'lifeskill', 'person', 'action']"
      :key="kind"
      class="section"
      :class="`section--${kind}`">
      <template v-if="kind === 'lifeskill'">
        <div class="left lineWithArrow">
          <div class="lineWithArrowLine">
            <svgicon
              class="lineWithArrowArrow"
              icon="play-triangle" />
          </div>
        </div>
        <div class="lifeskillTextContainer">
          <div class="xxx">
            {{ $t('lifeskillSymbol') }}
          </div>
          <div class="lifeskillLabel code">
            {{ item.life_area }}
          </div>
          <div class="xxx">
            {{ $t('connect') }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="left">
          <div class="kind">
            {{ $t(`${kind}`) }}
          </div>
          <div
            v-if="kind === 'object'"
            class="numbershapeContainer">
            <AnimatedNumbershape
              v-observe-visibility="visibilityChanged"
              :number="item.number"
              play
              :paused="!animationIsVisible" />
          </div>
          <div
            v-else
            class="image"
            :style="{ backgroundImage: `url(${item[kind + 'Image']})` }" />
        </div>
        <div class="textContainer">
          <div class="code">
            {{ item[kind + '_code'] }}
          </div>
          <div
            v-if="kind === 'object'"
            class="animationDescription">
            {{ item.animation_description }}
          </div>
          <div
            class="explanation"
            v-html="item[kind + '_explanation']" />
        </div>
      </template>
    </div>
  </div>
</template>

<translations>
  object: 'object'
  object_no: 'objekt'
  lifeskill: 'lifeskill'
  lifeskill_no: 'livsområde'
  person: 'person'
  person_no: 'person'
  action: 'action'
  action_no: 'handling'
  lifeskillSymbol: 'Symbolizes lifeskill'
  lifeskillSymbol_no: 'Symboliserer livsområdet'
  connect: 'Connects to person and action'
  connect_no: 'Kobles til person og handling'
</translations>

<script>
import AnimatedNumbershape from '@/components/AnimatedNumbershape';

export default {
    components: { AnimatedNumbershape },
    inject: ['config'],
    props: {
        itemId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            animationIsVisible: true
        };
    },
    computed: {
        item() {
            return this.config.items[this.itemId];
        }
    },
    methods: {
        visibilityChanged(isVisible, entry) {
            this.animationIsVisible = isVisible;
        }
    }
};
</script>

<style scoped lang="scss">
.Item {
    background-color: rgba(black, 0.05);
    border-radius: 0.5em;
    padding: 1em;
    padding-bottom: 2em;
    color: white;
}
.name {
    font-size: 200%;
    text-align: center;
    text-transform: capitalize;
}
.divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 1em 0;
}
// .lifeskillLabel {
//   text-align: center;
//   margin-top: 0.5em;
// }
.animationDescription {
    color: rgba(white, 0.7);
    margin-top: 1em;
}

.AnimatedNumbershape {
    flex-shrink: 0;
}
.AnimatedNumbershape {
    $size: 5em;
    width: $size;
    height: $size;
    margin: auto;
}

.section {
    display: flex;
    align-items: center;
    margin-top: 2em;
}
.kind {
    // background-color: black;
    // color: white;
    // padding: 0.2em 1em;
    color: rgba(white, 0.5);
    font-size: 80%;
    text-transform: uppercase;
    text-align: center;
}
.left {
    margin-right: 1em;
}
// .row {
//   display: flex;
//   padding: 1em 0;
// }
.code {
    font-weight: 600;
}
.explanation {
    margin-top: 1em;
    color: rgba(white, 0.7);
    line-height: 1.4em;
}
.image,
.numbershapeContainer {
    $size: 6em;
    width: $size;
    height: $size;
    border-radius: 30%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    flex-shrink: 0;
    margin-top: 0.7em;
}
.numbershapeContainer {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section--lifeskill {
    margin-top: 0.3em;
}
.lineWithArrow {
    // position: relative;
    width: 6em;
    display: flex;
    justify-content: center;
    align-self: stretch;
}
.lineWithArrowLine {
    width: 1px;
    background-color: rgba(white, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}
.lineWithArrowArrow {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
    transform: rotate(90deg);
    flex-shrink: 0;
}
.lifeskillTextContainer {
    margin-top: 1em;
    // color: rgba(white, 0.7);
}
.lifeskillLabel {
    margin: 1em 0;
    font-style: italic;
}
.xxx {
    color: rgba(white, 0.7);
    font-style: italic;
    font-size: 90%;
}
</style>
