<template>
  <div class="PracticeGameItem">
    <div
      class="icon"
      :style="{ backgroundImage: `url(${image})` }" />
    <div
      v-t="`memogenius.games.${gameId}.title`"
      class="name" />
  </div>
</template>

<script>
const images = require.context('@/assets/images/memogenius/game/icon/', true, /\.(png|jpg)$/);

export default {
    // inject: [ 'config' ]
    props: {
        gameId: {
            type: String,
            required: true
        }
    },
    computed: {
        image() {
            try {
                return images(`./${this.gameId}.png`);
            } catch (err) {
                return null;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.PracticeGameItem {
    // padding: 0.5em 2em;
    color: rgba(white, 0.8);
    // display: flex;
    // flex-direction: column;
    // padding-top: 7em;
    display: flex;
    align-items: center;
}

.icon {
    $size: 3em;
    width: $size;
    height: $size;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    margin-right: 1em;

    // transition: transform 0.3s;
}
</style>
