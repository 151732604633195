import keyBy from 'lodash-es/keyBy';
import last from 'lodash-es/last';
import Item from './components/Item';
import MemorizeItem from './components/MemorizeItem';
import PracticeConfiguration from './components/PracticeConfiguration';
import SuperGroupHeader from './components/SuperGroupHeader';
import backgroundImage from './images/background.png';
import MultipleChoiceTest from './components/MultipleChoiceTest';
import OpaTest from './components/OpaTest';

import itemsRaw from './data';
import categories from './categories';

const groupSizes = categories.reduce((acc, category) => acc.concat(category.groupSizes), []);

let lastGroupIndex = 0;
export const superGroups = categories.map((category, i) => {
    const startIndex = lastGroupIndex;
    lastGroupIndex += category.groupSizes.length;
    return {
        index: i,
        groupIndices: category.groupSizes.map((size, i) => startIndex + i),

        // NOTE: Props needed for SuperGroupHeader:
        categoryId: category.id,
        lifeskillRange: category.range
    };
});

function chunk(items, chunkSizes) {
    let startIndex = 0;
    return chunkSizes.map(size => {
        const chunk = items.slice(startIndex, startIndex + size);
        startIndex += size;
        return chunk;
    });
}

export function chunkItems(items) {
    return chunk(items, groupSizes);
}

export default function generateConfig({ language }) {
    const items = itemsRaw.map(rawItem => {
        const item = Object.assign({ id: `${rawItem.number}-opa`, number: rawItem.number }, rawItem[language]);
        item.objectImage = rawItem.objectImage || `${rawItem.number}`;
        item.personImage = rawItem.personImage;
        item.actionImage = rawItem.actionImage;

        return item;
    });
    const itemsMap = keyBy(items, item => item.id);
    return {
        items: itemsMap,
        itemIds: items.map(item => item.id),
        superGroups,
        itemGroups: chunkItems(items).map((sublist, index) => {
            return {
                index,
                label: `${sublist[0].number}-${last(sublist).number}`,
                itemIds: sublist.map(x => x.id)
            };
        }),
        BrowseItem: Item,
        MemorizeItem,
        PracticeConfiguration,
        SuperGroupHeader,
        backgroundImage,
        systemId: 'olpa',
        components: {
            MultipleChoiceTest,
            OpaTest
        },

        getImagesInGroup(group) {
            const images = [];
            group.itemIds
                .map(itemId => itemsMap[itemId])
                .forEach(item => {
                    images.push(item.objectImage);
                    images.push(item.personImage);
                    images.push(item.actionImage);
                });
            return images;
        }
    };
}
