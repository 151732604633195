import Vue from 'vue';
import { updateUserSetting } from '@/store/persistence';
import quizModule from './quiz-module';
import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';

const module = {
    namespaced: true,
    modules: {
        quiz: quizModule
    },
    state() {
        return {
            loaded: false,
            systemId: '',
            items: {},
            itemGroups: []
        };
    },
    actions: {
        async load({ state, commit }, config) {
            commit('setSystemId', config.systemId);
            commit(
                'setItemGroups',
                config.itemGroups.map(group => {
                    return {
                        itemIds: group.itemIds
                    };
                })
            );
            await apolloClient
                .query({
                    query: gql`
                        query {
                            userSettingsList {
                                key
                                value
                            }
                        }
                    `
                })
                .then(result => result.data)
                .then(data => {
                    data.userSettingsList
                        .filter(setting => setting.key.startsWith('system:' + config.systemId))
                        .forEach(setting => {
                            commit(`setUserstate`, { items: setting.value || {} });
                        });
                });
            // await persistence.read([
            //   `memogenius-system-${state.systemId}`
            // ])
            commit('setLoaded');
        },
        unload() {
            //
        },
        toggleLearnStatus({ commit, state }, { itemId }) {
            const status = (state.items[itemId] || {}).learned || 0;
            commit('setLearnStatus', { itemId, value: status ? 0 : 1 });
            updateUserSetting('system:', state.systemId, state.items);
        },
        setLearnStatus({ commit, state }, { itemId, value }) {
            commit('setLearnStatus', { itemId, value });
            updateUserSetting('system:', state.systemId, state.items);
        }
    },
    mutations: {
        setLearnStatus(state, { itemId, value }) {
            if (state.items[itemId]) {
                state.items[itemId].learned = value;
            } else {
                Vue.set(state.items, itemId, { learned: value });
            }
        },
        setSystemId(state, systemId) {
            state.systemId = systemId;
        },

        setItemGroups(state, itemGroups) {
            state.itemGroups = itemGroups;
        },

        setUserstate(state, { items }) {
            state.items = items;
        },

        setLoaded(state) {
            state.loaded = true;
        }
    },

    getters: {
        learnProgress(state, getters, rootState, rootGetters) {
            let totalCount = 0;
            let learnedCount = 0;
            state.itemGroups.forEach(group => {
                group.itemIds.forEach(itemId => {
                    totalCount++;
                    if (state.items[itemId] && state.items[itemId].learned) {
                        learnedCount++;
                    }
                });
            });
            return learnedCount / totalCount;
        },
        getGroupProgress: state => groupIndex => {
            const group = state.itemGroups[groupIndex];
            let learnedCount = 0;
            group.itemIds.forEach(itemId => {
                if (state.items[itemId] && state.items[itemId].learned) {
                    learnedCount++;
                }
            });
            return learnedCount / group.itemIds.length;
        },
        getGroupLocked: (state, getters) => groupIndex => {
            return !(groupIndex === 0 || getters.getGroupProgress(groupIndex - 1) === 1);
        },
        getGroupFocus: (state, getters) => groupIndex => {
            let i = state.itemGroups.length;
            while (i--) {
                if (getters.getGroupProgress(i) === 1) {
                    break;
                }
            }
            return i + 1 === groupIndex;
        }
    }
};

export default module;
