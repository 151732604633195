<template>
  <div
    class="AppViewport"
    :style="{ background }">
    <div
      class="viewport"
      :style="{ background: viewportBackground }">
      <!-- <slot/> -->
      <router-view
        class="AppViewport__routerView"
        :style="{ backgroundImage: `url(${config.backgroundImage})` }" />
    </div>
  </div>
</template>

<script>
// import ModalContainer from './ModalContainer'
// import DropdownHost from '@/components/DropdownHost'

export default {
    // components: { ModalContainer, DropdownHost },
    inject: ['config'],
    props: {
        background: {
            type: String,
            default: 'black'
        },
        viewportBackground: {
            type: String,
            default: 'white'
        }
    }
};
</script>

<style scoped lang="scss">
$viewPortWidth: 375;

.AppViewport {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $appFontSize;
}
.viewport {
    position: relative;
    width: #{$viewPortWidth}px;
    max-width: #{$viewPortWidth}px;
    height: 100%;
    max-height: 812px;
    color: white;

    overflow: scroll;
    // overflow: hidden;
    // & > *:first-child {
    //   height: 100%;
    // }
}
@media only screen and (max-width: #{$viewPortWidth}px) {
    .AppViewport {
        font-size: #{$appFontSize / 1px * 100 / $viewPortWidth}vw;
    }
}

.AppViewport__routerView {
    height: 100%;
}
</style>
