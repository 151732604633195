<template>
  <div class="MemorizeItem">
    <div class="spacerTop" />
    <Item :item-id="itemId" />
    <div class="spacerBottom" />
  </div>
</template>

<script>
import Item from './Item';

export default {
    components: { Item },
    inject: ['config'],
    props: {
        itemId: {
            type: String,
            required: true
        }
    },
    computed: {
        item() {
            return this.config.items[this.itemId];
        }
    }
};
</script>

<style scoped lang="scss">
.MemorizeItem {
    padding: 1em;
    display: flex;
    flex-direction: column;
}
.spacerTop {
    flex: 0.5;
}
.spacerBottom {
    flex: 1;
}
</style>
