import get from 'lodash-es/get';

export default function wrapVuexModule(store, path) {
    return {
        state: get(store.state, path.replace('/', '.')),
        commit(funcName, payload) {
            store.commit(`${path}/${funcName}`, payload);
        },
        dispatch(actionName, payload) {
            return store.dispatch(`${path}/${actionName}`, payload);
        },
        getters(key) {
            return store.getters[`${path}/${key}`];
        }
    };
}
