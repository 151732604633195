<template>
  <div class="MultipleChoiceTest">
    <div class="instruction">
      {{ $t('title') }}
    </div>
    <div class="numbershapeContainer">
      <AnimatedNumbershape
        :number="item.number"
        play />
    </div>

    <div class="spacer" />
    <MultipleChoiceInputStandard
      ref="input"
      :key="attemptCnt"
      :items="test.options"
      :selections="selectedFragmentIndices"
      @item-click="selectChoice" />
  </div>
</template>

<translations>
  title: 'Which lifeskill is this icon?'
  title_no: 'Hvilket livsområde hører til dette ikonet?'
</translations>

<script>
import AnimatedNumbershape from '@/components/AnimatedNumbershape';
import MultipleChoiceInputStandard from '@/modules/games-shared/components/MultipleChoiceInputStandard';

export default {
    components: { MultipleChoiceInputStandard, AnimatedNumbershape },
    inject: ['module', 'config'],
    provide() {
        return {
            theme: {
                // NOTE: Needed for the MultipleChoiceInputStandard
            }
        };
    },
    props: {
        itemId: {
            type: String,
            required: true
        },
        testIndex: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            selectedFragmentIndices: [],
            attemptCnt: 0
        };
    },
    computed: {
        item() {
            return this.config.items[this.itemId];
        },
        quizItem() {
            return this.module.state.quiz.items[this.itemId];
        },
        test() {
            return this.quizItem.tests[this.testIndex];
        }
    },
    watch: {
        'test.done'() {
            this.$nextTick(() => {
                this.$refs.input.playFeedbackCorrect().then(() => {
                    this.$emit('test-completed');
                });
            });
        }
    },
    methods: {
        selectChoice(choiceIndex) {
            this.selectedFragmentIndices.push(choiceIndex);
            if (this.test.options[choiceIndex] === this.test.fasit) {
                this.module.dispatch('quiz/updateTest', {
                    itemId: this.itemId,
                    testIndex: this.testIndex,
                    data: { done: true }
                });
            } else {
                this.playFeedbackWrongAndReset();
            }
        },
        playFeedbackWrongAndReset() {
            this.$nextTick(() => {
                this.$refs.input.playFeedbackWrong().then(() => {
                    this.selectedFragmentIndices = [];
                    this.attemptCnt += 1;
                });
            });
        },
        cheat() {
            this.selectedFragmentIndices.push(0);
            this.module.dispatch('quiz/updateTest', {
                itemId: this.itemId,
                testIndex: this.testIndex,
                data: { done: true }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.MultipleChoiceTest {
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
}
.instruction {
    font-size: 140%;
    text-align: center;
}
.numbershapeContainer {
    $size: 6em;
    width: $size;
    height: $size;
    border-radius: 30%;

    flex-shrink: 0;

    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: auto;
    margin-top: 1em;
}

.AnimatedNumbershape {
    $size: 5em;
    width: $size;
    height: $size;
    margin: auto;
}
.spacer {
    flex: 1;
}
</style>
