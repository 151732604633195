<template>
  <div class="PracticeConfiguration">
    <LifeskillRangeSelectionSection @selected-numbers-changed="onSelectedNumbersChanged" />
    <br>
    <GameSelectionSection
      :game-ids="gameIds"
      :selected-game-id="selectedGame"
      @game-selected="gameId => (selectedGame = gameId)" />
  </div>
</template>

<script>
import shuffle from 'lodash-es/shuffle';
import zipWith from 'lodash-es/zipWith';

import gameHelper from '@/modules/games-shared/game-helper';
import GameSelectionSection from '@/modules/systems-shared/components/GameSelectionSection';
import LifeskillRangeSelectionSection from '@/modules/system-olpa/components/LifeskillRangeSelectionSection';

export default {
    components: { LifeskillRangeSelectionSection, GameSelectionSection },
    inject: ['config', 'module'],
    data() {
        return {
            selectedNumbers: [],
            selectedGame: null,
            gameIds: ['bankvault']
        };
    },
    computed: {
        isReady() {
            return !!this.selectedGame && this.selectedNumbers.length;
        }
    },
    watch: {
        isReady: {
            handler: function (val) {
                this.$emit('ready-status-changed', val);
            },
            immediate: true
        }
    },
    methods: {
        onSelectedNumbersChanged(numbers) {
            this.selectedNumbers = numbers;
        },
        selectGame(gameId) {
            this.selectedGame = gameId;
        },
        getGameSessionOptionsForBankvault() {
            const items = zipWith(
                shuffle(this.selectedNumbers),
                shuffle(this.selectedNumbers),
                shuffle(this.selectedNumbers),
                function (a, b, c) {
                    return {
                        ring1: a[0],
                        ring2: a[1],
                        inner1: b,
                        inner2: c
                    };
                }
            );
            return {
                items,
                itemMemorizeTimeLimit: 30,
                itemQuizTimeLimit: 30,
                chunks: items.length
            };
        },
        getGameSessionOptions() {
            if (this.selectedGame === 'bankvault') {
                return this.getGameSessionOptionsForBankvault();
            }
        },
        startPractice() {
            // NOTE: used from parent component
            if (!this.isReady) {
                return;
            }

            gameHelper.createPracticeSession({
                gameId: this.selectedGame,
                exitUrl: this.$route.path,
                sessionOptions: this.getGameSessionOptions(),
                navigate: true
            });
        }
    }
};
</script>

<style scoped lang="scss">
.PracticeConfiguration {
    display: flex;
    flex-direction: column;
}
</style>
