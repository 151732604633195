<template>
  <div class="OpaTest">
    <div class="instruction">
      {{ $t('instruction') }}
    </div>

    <br>
    <div class="number">
      {{ item.number }}
    </div>
    <br>
    <SourceTargetInteraction
      :target-ids="targetIds"
      :source-ids="sourceIds"
      :check-match="checkMatch"
      source-selector=".ShelfItem"
      target-selector=".Target__dropzone">
      <template
        slot="default"
        slot-scope="{ sourceItems, targetItems }">
        <div class="targets">
          <div
            v-for="targetItem of targetItems"
            :key="targetItem.id"
            class="Target">
            <div class="Target__label">
              {{ $t(targetItemsMap[targetItem.id].label) }}
            </div>

            <TargetItem
              class="Target__dropzone"
              :data-id="targetItem.id"
              :data="{}"
              :other="sourceItemsMap[targetItem.id]"
              v-bind="targetItem" />
          </div>
        </div>

        <div class="spacer" />
        <div class="shelf">
          <ShelfItemWithImage
            v-for="sourceItem of sourceItems"
            :key="sourceItem.id"
            class="ShelfItem"
            :state="sourceItem"
            v-bind="sourceItemsMap[sourceItem.id]"
            :data-id="sourceItem.id"
            :class="{ used: sourceItem.used }"
            :style="{ '--local-size': '5em' }" />
        </div>
      </template>
      <template
        slot="dragItem"
        slot-scope="props">
        <ShelfItemWithImage
          class="dragItem"
          :state="{}"
          v-bind="sourceItemsMap[props.id]"
          :style="{ '--local-size': '6em' }" />
      </template>
    </SourceTargetInteraction>
  </div>
</template>

<translations>
  instruction: 'Put items in the right place'
  instruction_no: 'Plasser i riktig boks'
  object: 'object'
  object_no: 'objekt'
  person: 'person'
  person_no: 'person'
  action: 'action'
  action_no: 'handling'
</translations>

<script>
import SourceTargetInteraction from '@/modules/games-shared/components/SourceTargetInteraction';
import TargetItem from '@/modules/quiz/components/MatchTargetItem';
import ShelfItemWithImage from '@/modules/games-shared/components/ShelfItemWithImage';

export default {
    components: { SourceTargetInteraction, TargetItem, ShelfItemWithImage },
    inject: ['config', 'module'],
    props: {
        itemId: {
            type: String,
            required: true
        },
        testIndex: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            matches: {}
        };
    },
    computed: {
        item() {
            return this.config.items[this.itemId];
        },
        quizItem() {
            return this.module.state.quiz.items[this.itemId];
        },
        test() {
            return this.quizItem.tests[this.testIndex];
        }
    },
    created() {
        const config = this.config;
        function getImage(targetId) {
            const [id, typ] = targetId.split(':');
            const imageKey = { p: 'personImage', o: 'objectImage', a: 'actionImage' }[typ];
            return config.items[id][imageKey];
        }

        const targetItems = [
            { label: 'object', id: this.test.fasit.o, image: getImage(this.test.fasit.o) },
            { label: 'person', id: this.test.fasit.p, image: getImage(this.test.fasit.p) },
            { label: 'action', id: this.test.fasit.a, image: getImage(this.test.fasit.a) }
        ];

        const sourceItems = this.test.options.map(x => {
            const [id, typ] = x.split(':');
            const imageKey = { p: 'personImage', o: 'objectImage', a: 'actionImage' }[typ];
            return { id: x, image: config.items[id][imageKey] };
        });

        this.sourceIds = sourceItems.map(item => item.id);
        this.targetIds = targetItems.map(item => item.id);

        const targetItemsMap = {};
        targetItems.forEach(targetItem => {
            targetItemsMap[targetItem.id] = targetItem;
        });

        const sourceItemsMap = {};
        sourceItems.forEach(sourceItem => {
            sourceItemsMap[sourceItem.id] = sourceItem;
        });

        this.targetItemsMap = targetItemsMap;
        this.sourceItemsMap = sourceItemsMap;
    },
    beforeDestroy() {
        clearTimeout(this.doneTimeoutId);
    },
    methods: {
        checkMatch(sourceId, targetId) {
            const match = sourceId === targetId;
            if (match) {
                this.matches[sourceId] = true;
                if (Object.keys(this.matches).length === 3) {
                    this.onCompleted();
                }
            }
            return match;
        },
        cheat() {
            this.module.dispatch('quiz/updateTest', {
                itemId: this.itemId,
                testIndex: this.testIndex,
                data: { done: true }
            });
            this.$emit('test-completed');
        },
        onCompleted() {
            this.module.dispatch('quiz/updateTest', {
                itemId: this.itemId,
                testIndex: this.testIndex,
                data: { done: true }
            });
            this.doneTimeoutId = setTimeout(() => {
                this.$emit('test-completed');
            }, 1000);
        }
    }
};
</script>

<style scoped lang="scss">
.OpaTest {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1em;
}
.number {
    font-size: 200%;
    color: rgba(white, 0.4);
    text-align: center;
}
.instruction {
    font-size: 140%;
    text-align: center;
}
.SourceTargetInteraction {
    flex: 1;
}
.targets {
    display: flex;
    justify-content: center;
}
.Target {
    &:not(:first-child) {
        margin-left: 1em;
    }
}
.Target__label {
    text-align: center;
    color: rgba(white, 0.5);
}
.Target__dropzone {
    margin-top: 1em;
    width: 6em;
    height: 6em;
}

.spacer {
    flex: 1;
}
.shelf {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    border-radius: 0.5em;
    background-color: rgba(white, 0.5);
}
.ShelfItem {
    margin: 0.5em;

    &.used {
        transition: opacity 0.3s, transform 0.3s;
        transform: scale(0.5);
        opacity: 0;
        pointer-events: none;
    }
}
</style>
