<template>
  <div class="ConfigurationSection">
    <div class="title">
      {{ title }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.ConfigurationSection {
}
.title {
    padding: 0.5em 2em;
    color: rgba(white, 0.5);
    margin-bottom: 1em;
}
</style>
